.RoomContainer{
    padding: 8px 16px;
    position: relative;
}
.jdiviF {
    font-size: 16px;
    margin: 0px 0px 4px;
    text-align: center;
    width: unset;
    font-weight: 700;
    color: initial;
}
.eTrcNZ {
    max-width: 310px;
    width: 100%;
    height: 65px;
    border: 2px solid rgb(147, 147, 147);
    border-radius: 6px;
    text-align: center;
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 8px;
    font-family: OpenSans, sans-serif;
}.eqifuM {
    font-size: 20px;
    margin: 0px 0px 8px;
    text-align: center;
    width: unset;
    font-weight: 700;
    color: initial;
}