.daBEHU {
    margin: 14px auto;
    max-width: 240px;
    width: 100%;
}
.iryvuY {
    display: flex;
    flex-direction: column;
    position: fixed;
    bottom: 0px;
    width: 100%;
    box-shadow: rgba(0, 0, 0, 0.25) 3px 0px 5px;
    background-color: rgb(255, 255, 255);
}
.bWsdkr {
    border-color: #D0D5DD;
    background-color: #D9D9D9;
}
.bbiTdk {
    width: 100%;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    border-radius: 6px;
    height: 48px;
    min-width: unset;
    padding: 12px 24px;
    border: 2px solid transparent;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}