.kZiKTv {
    padding: 16px;
}
.gVomYX {
    font-size: 16px;
    text-align: center;
    width: unset;
    font-weight: 700;
    margin: 6px 0px;
    color: initial;
}
.jHnEBJ {
    font-family: OpenSans, sans-serif;
    border: 2px solid rgb(147, 147, 147);
    border-radius: 6px;
    width: 100%;
    height: 110px;
    padding: 10px;
}