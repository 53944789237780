.Header{display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid rgb(215, 215, 215);}
    .Logoload{
        height: 120px;
    margin: 8px auto 4px;
    }
    .LogoStyle{
        color: gray;
        font-weight: bold;
    }