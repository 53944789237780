.MainContainer{
    margin: 0px auto 130px;
    max-width: 320px;
    width: 100%;
    DISPLAY: flex;
    FLEX-DIRECTION: column;
    ALIGN-CONTENT: CENTER;
    JUSTIFY-CONTENT: CENTER;
}
.jnjpMY {
    font-size: 14px;
    margin: 12px 0px 0px;
    text-align: center;
    width: unset;
    font-weight: 500;
    color: initial;
}