
.headingtermandprivast {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
  text-align: center;
  padding-right: 40px; /* Adjust padding to make space for the close icon */
}

.close-icon {
  position: absolute; /* Position relative to the privacycontainer */
  top: 10px; /* Position 10px from the top */
  right: 10px; /* Position 10px from the right */
  cursor: pointer;
}

.Contentoftermandcondition {
  font-size: 16px;
  line-height: 1.6;
  color: #333;
  overflow-y: auto;
  padding-right: 15px; /* For scrollbar space */
  height: calc(100% - 60px); /* Adjust height to fit within the container */
}

.Contentoftermandcondition p {
  margin-bottom: 15px; /* Adds space between paragraphs */
}

.Contentoftermandcondition h2 {
  font-size: 20px; /* Adjusts the size of headings */
  color: #000; /* Makes heading text black */
  margin-top: 20px; /* Adds space above headings */
  margin-bottom: 10px; /* Adds space below headings */
  font-weight: bold; /* Makes headings bold */
}

.Contentoftermandcondition ul {
  list-style-type: disc; /* Sets the list style to disc */
  margin-left: 20px; /* Adds left margin to the list */
  padding-left: 20px; /* Adds padding to the left of the list */
}

.Contentoftermandcondition ul li {
  margin-bottom: 10px; /* Adds space between list items */
  color: #333; /* Sets the list item text color */
}

.Contentoftermandcondition a {
  color: #007BFF; /* Makes links blue */
  text-decoration: underline; /* Underlines links */
  cursor: pointer; /* Changes cursor to pointer on hover */
}

.Contentoftermandcondition a:hover {
  color: #0056b3; /* Changes link color on hover */
}

.Mainboxprivacy{
    display: flex;
    flex-direction: column;
    font-size: x-small;
    text-align: center;
}
.termandcondi{
    align-self: center;
}
.Termprivacycolor{
    color: blue;
}

.headingtermandprivast{
    text-align: center;
margin-top: 10px;
font-weight: 500;
font-size: large;
}
.Contentoftermandcondition{
    overflow: auto;
}
.Contentoftermandcondition {
    padding: 20px; /* Adds padding inside the container */
    color: #333; /* Sets default text color */
    font-family: Arial, sans-serif; /* Sets a clean font */
    font-size: 16px; /* Sets a default font size */
    line-height: 1.6; /* Increases line height for better readability */
    border-radius: 10px; /* Adds rounded corners to the container */
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Adds a subtle shadow */
    margin-bottom: 20px; /* Adds margin below the container */
  }
  
  .Contentoftermandcondition p {
    margin-bottom: 15px; /* Adds space between paragraphs */
  }
  
  .Contentoftermandcondition h2 {
    font-size: 20px; /* Adjusts the size of headings */
    color: #000; /* Makes heading text black */
    margin-top: 20px; /* Adds space above headings */
    margin-bottom: 10px; /* Adds space below headings */
    font-weight: bold; /* Makes headings bold */
  }
  
  .Contentoftermandcondition ul {
    list-style-type: disc; /* Sets the list style to disc */
    margin-left: 20px; /* Adds left margin to the list */
    padding-left: 20px; /* Adds padding to the left of the list */
  }
  
  .Contentoftermandcondition ul li {
    margin-bottom: 10px; /* Adds space between list items */
    color: #333; /* Sets the list item text color */
  }
  
  .Contentoftermandcondition a {
    color: blue; /* Makes links blue */
    text-decoration: underline; /* Underlines links */
    cursor: pointer; /* Changes cursor to pointer on hover */
  }
  
  .Contentoftermandcondition a:hover {
    color: darkblue; /* Changes link color on hover */
  }
  