.privacycontainerPrv {
    position: fixed; /* Fixes the container to the viewport */
    bottom: 0; /* Positions the container at the bottom of the viewport */
    left: 0; /* Aligns the container to the left edge of the viewport */
    width: 100%; /* Makes the container full width */
    height: 75%; /* Sets the container height to 75% of the viewport */
    background-color: #ffffff; /* Light background color for a clean look */
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    box-shadow: 0 -4px 10px rgba(0, 0, 0, 0.2); /* Soft shadow for depth */
    overflow-y: auto; /* Allows scrolling if content exceeds height */
    z-index: 9999; /* Ensures the container is on top of all other elements */
    padding: 20px;
    display: none;
  }
  