.cABmFL {
  padding: 8px 16px;
  position: relative;
}
.jdiviF {
  font-size: 16px;
  margin: 0px 0px 4px;
  text-align: center;
  width: unset;
  font-weight: 700;
  color: initial;
}
.bLFZKQ {
  display: grid;
    width: 100%;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: auto auto;
    gap: 10px;
    padding: 0 5%;
    box-sizing: border-box;
    justify-content: center;
    margin-top: 9px;
}
.bkVhky {
   display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    border: 1px solid var(--gray-300, #D0D5DD);
    color: #000;
    text-align: center;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: -0.4px;
    height: 70px;
    width: 90px;
}
.fdCraf {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 140px;
  height: 44px;
  background-color: rgb(35, 124, 157);
  border-radius: 6px;
  margin-bottom: 8px;
}
.sc-dgDNnZ.selected {
  border-color: #D0D5DD;
  background-color: #D9D9D9;
}
