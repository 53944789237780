.logoc{
    padding: 8px 16px;
    height: 100px;
    width: 200px;
    margin: 0px auto;
}
.imageges{
    height:100px; 
    width:200px;
    border-radius: 9px;
}